import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Tab, Nav } from 'react-bootstrap'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import styles from './Login.module.css';
import Logo from "../../../assets/images/dk-global-rec-logo.png";
import DKGCrm from "../../../assets/images/dkgcrmlogo.png";
import { FaUser } from "react-icons/fa";
import LoginHeader from '../../elements/header/LoginHeader'
import LoginmoHeader from '../../elements/header/LoginmoHeader'


import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from "../../../slice/utils/message/messageSlice"
import { loginUser, adminlogin, clearState } from "../../../slice/auth/authSlice";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useCookies } from 'react-cookie';
import moment from 'moment'
import "./style.scss";
import ForgetPasswordModal from './forget/ForgetPassword'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: 40,
        marginBottom: 40,
    },
    margin: {
        margin: theme.spacing(1),
    },
    activeLoginBtnn: {
        backgroundColor: '#10333c'
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(20),
        width: '25ch',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -12,
    },
    // loginButtonsTabUser: {
    //     backgroundColor: '#10333c !important',
    //     border: 'none',
    //     borderRadius: '0'
    // },
    loginButtonsTabAdmin: {
        backgroundColor: '#2b7a8a !important',
        color: '#fff !important',
        borderRadius: '0',
        border: 'none'
    },
    activeLoginBtn: {
        backgroundColor: '#fff'
    }
}));
const SupplierLogin = () => {
    const classes = useStyles();
    // const [activeUser, setActiveUser] = useState('activeLoginBtn');
    // const [activeAdmin, setActiveAdmin] = useState('');

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    //const [role, setRole] = useState('User');

    const [cookies, setCookie] = useCookies(['user']);
    const [email, setEmail] = useState(cookies.DKMasterboxUserEmail);
    const [password, setPassword] = useState(cookies.DKMasterboxUserPwd);

    const [showPassword, setShowPassword] = React.useState(false);

    const { isSuccess, isError, isLoading, adminToken, errorMessage, isAdminLoginSuccess } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });


    // const clickSetRole = (roleType) => {
    //     setRole(roleType)
    //     if (roleType === 'User') {
    //         setActiveUser('activeLoginBtn')
    //         // setActiveAdmin('')
    //     } else {
    //         setActiveUser('')
    //         setActiveAdmin('activeLoginBtn')
    //     }
    // }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;

            case "password":
                setPassword(value)
                break;

            default:
                break;
        }
    }

    const handleButtonClick = (LoginType) => {
        if (!email || !password) {
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            try {
                dispatch(clearMessage())
                setCookie('DKMasterboxUserEmail', email, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });
                setCookie('DKMasterboxUserPwd', password, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });
                if (LoginType === "User") {
                    dispatch(loginUser({ role: LoginType, email, password }))
                } else if (LoginType === "Admin") {
                    dispatch(adminlogin({ role: LoginType, email, password }))
                } else {
                    dispatch(showError({ msg: "You are not authorized to login" }))
                }

                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                } else {
                    setSuccess(true);
                    setLoading(false);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        document.title = "Login | DK Global CRM"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState());
            setSuccess(true);
            setLoading(false);
        }
        if (isAdminLoginSuccess) {
            dispatch(clearState())
            window.location.assign(process.env.REACT_APP_REDIRECT_URL + adminToken.access_token);
        }
    }, [isError, isSuccess, errorMessage, isAdminLoginSuccess, dispatch]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [isForgetPassword, setIsForgetPassword] = useState(false);

    const showforgetpassModal = () => {
        setIsForgetPassword(true)
    }
    const hideforgetpassModal = () => {
        setIsForgetPassword(false)

    }
    return (
        <React.Fragment>
            {
                (isForgetPassword) ? <ForgetPasswordModal onHide={hideforgetpassModal} /> : null
            }
            <LoginHeader />
            <LoginmoHeader />
            <div className={styles.containerLogin + " " + styles.bg}>
                <div className="dkg-crm-login-mainCon">
                    <div className='dkg-crm-login-mainLogoCon mb-5 d-none'>
                        <div className='dkg-crm-login-mainLogos'>
                            <div className={styles.formLogo} style={{ marginRight: "60px" }}>
                                <img src={Logo} width="100%" alt="logo" id="imglogo" className="loginImg" style={{ width: "200px" }} />
                            </div>
                            <div className={styles.formLogo}>
                                <img src={DKGCrm} width="100%" alt="DKG CRM" id="imglogo" className="loginImg" style={{ width: "200px" }} />
                            </div>
                        </div>
                    </div>
                    <div className='dkg-crm-login-mainLogoCon mb-3'>
                        <div className="dkg-login-form-header">
                            <h2 className='dkg-login-form-title'>FREELANCE RECRUITER LOGIN</h2>
                        </div>
                    </div>
                    <div className='dkg-crm-login-type-Con'>
                        <div className='dkg-crm-login-type-cols'>
                            <div className={`dkg-supplier-login-bg ${styles.wrapLogin}`}>
                                <div className="w-100 dk-loginBtnTabs dk-gap-bt-logo-inputtype">
                                    <div className={`${styles.formLogo}`}>
                                        <img src={Logo} width="100%" alt="logo" id="imglogo" className="loginImg" style={{width: "200px"}} />
                                    </div>
                                    <div className="dk-loginTabsMain mt-5">
                                        <Tab.Container defaultActiveKey="editdkg">
                                            <div className='row'>
                                                <div className='col-md-12 pt-2'>
                                                    <Nav variant="pills dk-loginform-tab-pills" className="row-column">
                                                        <Nav.Item className='dk-loginform-tab-navitem'>
                                                            <Nav.Link eventKey="editdkg">DASHBOARD </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className='dk-loginform-tab-navitem'>
                                                            <Nav.Link eventKey="reorder"> ADMIN PANEL </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                                <div className='col-md-12'>
                                                    <Tab.Content className='dk-loginform-tab-tabcontent'>
                                                        <Tab.Pane eventKey="editdkg" className='dk-loginform-tab-tabpane'>
                                                            <form autoComplete="off">
                                                                <div className="col-12">
                                                                    <span className="passwordIcon"><i className="fas fa-envelope"></i></span>
                                                                    <input
                                                                        name="email"
                                                                        className={classes.margin + classes.textField}
                                                                        id="input-with-icontextfield_1"
                                                                        label=""
                                                                        placeholder="Username"
                                                                        autoComplete="off"
                                                                        onChange={handleChange}
                                                                        value={email}
                                                                        style={{ background: 'transparent' }}
                                                                    />

                                                                </div>
                                                                <div className="col-12 dk-mt-4">
                                                                    <span className="passwordIcon"><i className="fas fa-lock"></i></span>
                                                                    <input
                                                                        type={showPassword ? "text" : "password"}
                                                                        name="password"
                                                                        className={classes.margin + classes.textField}
                                                                        id="input-with-icon-2textfield"
                                                                        label=""
                                                                        placeholder="Password"
                                                                        autoComplete="off"
                                                                        onChange={handleChange}
                                                                        value={password}
                                                                        style={{ background: 'transparent' }}
                                                                    />

                                                                    <span className="dk-passHideShow" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                    </span>
                                                                </div>
                                                            </form>
                                                            <div className={classes.wrapper + " col-12 mt-4 d-flex justify-content-center"}>
                                                                <Button variant="contained" className={styles.loginButton + " mt-4 mb-4 " + buttonClassname} disabled={loading} onClick={(e) => handleButtonClick('User')}><Typography className="dklogin-titleColor">Login</Typography></Button>
                                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                            </div>
                                                            <div className="col-12 mt-3 d-flex justify-content-center">
                                                                <Link to="#" onClick={showforgetpassModal} className={styles.forgetPassword}><Typography>Forget Password</Typography></Link>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="reorder" className='dk-loginform-tab-tabpane'>
                                                            <form autoComplete="off" id="formId">
                                                                <div className="col-12">
                                                                    <span className="passwordIcon" id="formId1"><i className="fas fa-envelope"></i></span>
                                                                    <input
                                                                        name="email"
                                                                        className={classes.margin + classes.textField}
                                                                        id="input-with-icon-3textfield"
                                                                        label=""
                                                                        placeholder="Username"
                                                                        autoComplete="off"
                                                                        onChange={handleChange}
                                                                        value={email}
                                                                        style={{ background: 'transparent' }}
                                                                    />

                                                                </div>
                                                                <div className="col-12 dk-mt-4">
                                                                    <span className="passwordIcon" id="formId2"><i className="fas fa-lock"></i></span>
                                                                    <input
                                                                        type={showPassword ? "text" : "password"}
                                                                        name="password"
                                                                        className={classes.margin + classes.textField}
                                                                        id="input-with-icon-textfield"
                                                                        label=""
                                                                        placeholder="Password"
                                                                        autoComplete="off"
                                                                        onChange={handleChange}
                                                                        value={password}
                                                                        style={{ background: 'transparent' }}
                                                                    />

                                                                    <span className="dk-passHideShow" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                                    </span>
                                                                </div>
                                                            </form>
                                                            <div className={classes.wrapper + " col-12 mt-4 d-flex justify-content-center"}>
                                                                {/* <Button variant="contained" className={styles.loginButton + " mt-4 mb-4 " + buttonClassname} href={"https://admin.dkglobalcrm.com/"} >Login</Button> */}

                                                                <Button variant="contained" className={styles.loginButton + " mt-4 mb-4 " + buttonClassname} disabled={loading} onClick={(e) => handleButtonClick('Admin')}><Typography className="dklogin-titleColor">Login</Typography></Button>
                                                            </div>
                                                            <div className="col-12 mt-3 d-flex justify-content-center">
                                                                <Link to="#" onClick={showforgetpassModal} className={styles.forgetPassword}><Typography>Forget Password</Typography></Link>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </Tab.Container>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SupplierLogin;